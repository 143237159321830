










































































































































































import {types} from "@/services/westore/enums";
import { PropType } from "vue";
import wsCommonForm from "./ws-common-form.vue";
import wsWhatsappPopup from "./ws-whatsapp-popup.vue";
import Vue from "vue";
export default Vue.extend({
  components: { wsCommonForm, wsWhatsappPopup },
  name: "ws-landing",
  props: {
    headline: String,
    subtitle: String,
    points: Array as PropType<Array<String>>,
    image: { type: [String, Object] },
    second_image: { type: [String, Object] },
    formTitle: String,
    submitTitle: String,
    bottomLine: String,
  },
  methods: {
    trackMyPageView() {
      setTimeout(() => {
        this.$gtag.pageview(<any>this.$route);
      }, 250);
    },

    logGAEvent(category: string, action: string, label?: string) {
      this.$gtag.event(action, {
        event_category: category,
        event_label: label,
      });
    },

    logGAException(exception: any, fatal = false) {
      this.$gtag.exception({ description: exception, fatal });
    },
  },
  computed: {
    types() {
      return types;
    }
  }
});
